<template>
  <div class="classLearning">
    <div class="top-box">
      <div class="check-box">
        <el-select
          v-model="subjectOptionsVal"
          placeholder="请选择"
          @change="changeOption"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="check-box-right">
          <el-select
            v-model="optionsTimeVal"
            placeholder="请选择"
            @change="changeOption"
          >
            <el-option
              v-for="item in optionsTime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="optionYearVal"
            placeholder="请选择"
            @change="getClass"
          >
            <el-option
              v-for="item in optionYear"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="optionsClassVal"
            placeholder="请选择班级"
            @change="subjectlistbygradeandclassnum"
          >
            <el-option
              v-for="item in optionsClass"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="examPaperIds"
            multiple
            collapse-tags
            placeholder="全部考试"
            class="set-input morelist"
            @change="setComType()"
          >
            <!-- <el-option label="所有考试" value=""> </el-option> -->
            <el-option
              v-for="item in examList"
              :key="item.examPaperId"
              :label="item.examName"
              :value="item.examPaperId"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <information
        v-if="comBoxType"
        :child-obj="childObj"
        :option-year="optionYear"
      ></information>
    </div>

    <knowTable v-if="comBoxType" :child-obj="childObj"></knowTable>
    <wrongBox
      v-if="comBoxType"
      :option-year="optionYear"
      :child-obj="childObj"
      :exam-list="examList"
      :exam-paper-ids="examPaperIds"
      @setPaperIds="setPaperIds"
    ></wrongBox>
  </div>
</template>

<script>
import { optionsTime, examTypeOptions } from "@/core/util/constdata";
import {
  getGradeList,
  classlistbygrade,
} from "@/core/api/school/schoolgradeclass";
import { subjectlistbygradeandclassnum } from "@/core/api/school/school";
import wrongBox from "@/components/learning/wrongBox.vue";
import knowTable from "@/components/learning/knowTable.vue";
import information from "@/components/learning/topMsg.vue";
import { getExamListByTimeRange } from "@/core/api/resource/learningPaper";

export default {
  name: "ClassLearning",
  components: {
    wrongBox,
    knowTable,
    information,
  },
  data() {
    return {
      //和错题组件对应的字段
      childObj: {},
      comBoxType: false,
      subjectOptions: [],
      subjectOptionsVal: "",
      optionsTimeVal: 0,
      optionsTime: optionsTime,
      optionYearVal: "",
      optionYear: [],
      optionsClassVal: "",
      optionsClass: [],
      examList: [],
      examPaperIds: [],
      examTypeOptions: examTypeOptions,
    };
  },
  created() {
    // this.subjectOptionsVal = this.subjectOptions[0].value;
    this.getOption();
  },
  methods: {
    setPaperIds(val) {
      // console.log(val);
      this.examPaperIds = val;
      this.setComType();
    },
    getExamListByTimeRange() {
      let data = {
        subjectId: this.subjectOptionsVal,
        timeRange: this.optionsTimeVal,
        gradeId: this.optionYearVal,
        classNums: this.optionsClassVal,
        levelId: this.optionYear.filter((i) => i.value == this.optionYearVal)[0]
          .level,

        // examType: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,99"
      };
      this.examList = [];
      this.examPaperIds = [];
      getExamListByTimeRange(data).then((res) => {
        // console.log(res);
        this.examList = res.data.data;
        let paperIds = [];
        this.examList.map((index) => {
          paperIds.push(index.examPaperId);
          let str = this.examTypeOptions.filter(
            (item) => index.type == item.value
          )[0].label;
          index.examName =
            index.examDate + " " + index.examName + "（" + str + "）";
        });
        this.setComType(paperIds.join(","));
      });
    },
    // 根据年级 班级获取科目列表
    subjectlistbygradeandclassnum() {
      this.subjectOptions = [];
      let data = {
        gradeId: this.optionYearVal,
        classNum: this.optionsClassVal,
      };
      subjectlistbygradeandclassnum(data).then((res) => {
        let isTrue =
          res.data.data.filter((item) => item.id == this.subjectOptionsVal)
            .length == 0;
        if (isTrue) {
          if (res.data.data.filter((item) => item.id == 2).length == 1) {
            this.subjectOptionsVal = 2;
          } else {
            this.subjectOptionsVal = res.data.data[0].id;
          }
        }
        res.data.data.forEach((index) => {
          this.subjectOptions.push({
            value: index.id,
            label: index.name,
          });
        });
        this.getExamListByTimeRange();
      });
    },
    changeOption() {
      this.getExamListByTimeRange();
    },
    setComType(paperIds) {
      this.comBoxType = false;
      this.childObj = {
        subjectId: this.subjectOptionsVal,
        timeRange: this.optionsTimeVal,
        gradeId: this.optionYearVal,
        classNum: this.optionsClassVal,
        level: this.optionYear.filter((i) => i.value == this.optionYearVal)[0]
          .level,
        paperIds: paperIds,
        examPaperIds: paperIds ? paperIds : this.examPaperIds.join(","),
        //
      };
      this.comBoxType = true;
    },
    //获取班级
    getClass() {
      let data = { gradeId: this.optionYearVal, fromType: 1 };
      this.optionsClass = [];
      this.optionsClassVal = "";
      classlistbygrade(data).then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            this.optionsClass.push({ value: item.classNum, label: item.name });
          });
          // 重点关注
          this.optionsClass = this.optionsClass.filter(
            (i) => i.value != "重点关注"
          );
          this.optionsClassVal = this.optionsClass[0].value;
          this.subjectlistbygradeandclassnum();
        }
      });
    },
    // 获取年级
    getOption() {
      getGradeList().then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            this.optionYear.push({
              value: item.id,
              label: item.name,
              level: item.level,
            });
            if (item.classCount && !this.optionYearVal) {
              this.optionYearVal = item.id;
            }
          });
          if (!this.optionYearVal) {
            this.optionYearVal = res.data.data[0].id;
          }
          this.getClass();
        }
      });
    },
    setMenu(i) {
      this.menuList.forEach((index) => {
        index.isClick = false;
      });
      this.menuList[i].isClick = true;
    },
  },
};
</script>
<style lang="scss">
.classLearning {
  .morelist {
    // overflow-x: scroll;
    width: 120px;

    input:-ms-input-placeholder {
      color: #606266 !important;
    }
    input::placeholder {
      color: #606266;
    }
    .el-select__tags {
      flex-wrap: unset;
      overflow: hidden;
      .el-tag {
        color: #606266;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.classLearning {
  width: 1220px;
  margin: 0 auto;
  .wrong-box {
    border: 1px solid #dddddd;
    padding: 80px 24px 24px;
    position: relative;
    margin: 24px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .right-box {
      border: 1px solid #dddddd;
      padding: 12px;
      width: 870px;
      .question-list {
        border: 1px solid #dddddd;
        width: 100%;
        margin-top: 20px;
        position: relative;
        .anaws-box {
          padding: 12px;
          margin: 12px 0;
          .anaws-box-title {
            color: $primary-color;
          }
        }

        .question-intro {
          display: flex;
          padding: 10px 30px;
          justify-content: space-between;
          align-items: center;
          background-color: #f5f5f5;

          span {
            margin-left: 20px;
            color: $primary-color;
            cursor: pointer;
          }
        }
        .question-box {
          margin: 24px 0;
          padding: 12px;
        }
        .quertion-num {
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: $primary-color;
          background-color: #f6f6f6;
        }
      }
      .right-box-top {
        display: flex;
        justify-content: space-between;
        background-color: #f6f6f6;

        .top-left {
          padding: 8px 16px;
        }
        .top-right {
          display: flex;
          .one-box {
            // &:first-child {
            color: $primary-color;
            // }
          }
          .right-list {
            padding: 8px 16px;
            border-left: 1px solid #dddddd;
            cursor: pointer;
            .el-icon--right {
              transform: rotateZ(180deg);
            }
            .el-icon-arrow-down:before {
              content: "";
              // ;
            }
          }
        }
      }
    }
    .left-box {
      width: 280px;
      border: 1px solid #dddddd;
      padding: 12px;
      .wrong-left-top {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 0 12px;
        position: relative;
        margin-bottom: 24px;
        &::after {
          position: absolute;
          width: 1px;
          height: 80%;
          content: "";
          left: 50%;
          top: 10%;
          background-color: #dddddd;
        }
        // div {
        //   &:last-child {
        //     border-left: 1px solid #dddddd;
        //   }
        // // }

        p {
          font-size: 20px;
        }
        span {
          color: #dddddd;
        }
      }
    }
    .wrong-title {
      position: absolute;
      left: 0;
      top: 24px;
      padding-left: 24px;
      color: $primary-color;
      font-size: 20px;
      &::after {
        position: absolute;
        width: 3px;
        height: 100%;
        background-color: $primary-color;
        content: "";
        left: 0;
        top: 0;
      }
    }
  }
  .knowledge-box {
    border: 1px solid #dddddd;
    /*padding: 24px;*/
    position: relative;
    .knowledge-top {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      div {
        padding: 6px 12px;
        border: 1px solid $primary-color;
        cursor: pointer;
      }
      .on {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
    .knowledge-title {
      position: absolute;
      left: 0;
      top: 24px;
      padding-left: 24px;
      color: $primary-color;
      font-size: 20px;
      &::after {
        position: absolute;
        width: 3px;
        height: 100%;
        background-color: $primary-color;
        content: "";
        left: 0;
        top: 0;
      }
    }
  }
  .top-box {
    border: 1px solid #dddddd;
    padding: 12px;
    margin: 20px 0;
    p {
      margin-bottom: 6px;
    }
    .check-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .el-select {
        width: 180px;
      }
      .check-box-right {
        .btn-all {
          padding: 8px 18px;
        }
        .el-select {
          padding-right: 12px;
        }
      }
    }
  }
}
</style>
